<template>
    <!-- SECTION: Template -->
    <div>
        <!-- ANCHOR: Header -->
        <header class="header position-fixed">        
            <!-- ANCHOR: LNB -->
            <div class="lnb-wrap d-flex justify-center">
                <div class="lnb c-contanier d-flex align-center justify-space-between">
                    <div class="lnb__logo">
                        <RouterLink
                            :to="{ name: 'HomePageSelectedLanguage' }"
                        >
                            <v-img
                                :src="require('@/assets/svg/others/logo-lecos.svg')"
                                alt="LecoS Logo"
                                width="100"
                                contain
                            />
                        </RouterLink>
                    </div>

                    <!-- ANCHOR: GNB(PC) -->
                    <div
                        ref="gnbWrap"
                        class="gnb-wrap d-flex align-center justify-end justify-md-center"
                        @mouseover="addGnbActiveClass"
                        @mouseleave="removeGnbActiveClass"
                    >
                        <div
                            v-if="$vuetify.breakpoint.smAndDown"
                            class="d-flex align-center"
                        >
                            <div class="mr-5">
                                <!-- 언어 선택 버튼 -->
                                <LanguageMenu />
                                <!-- // 언어 선택 버튼 -->
                            </div>
                    
                            <v-btn                            
                                icon
                                color="gray3"
                                :width="$vuetify.breakpoint.xsOnly ? 40 : 48"
                                :height="$vuetify.breakpoint.xsOnly ? 40 : 48"
                                :ripple="false"
                                plain
                                @click="toggleDrawerMenu"
                            >
                                <v-icon :size="$vuetify.breakpoint.xsOnly ? 40 : 48">
                                    fontagon-icons ft-icon ft-ic_hamburger_outline
                                </v-icon>
                            </v-btn>
                        </div>

                        <div
                            v-else
                            class="gnb d-flex"
                        >
                            <ul class="gnb__ul flex d-flex justify-space-between align-center">
                                <li
                                    v-for="(item, index) in gnbMainMenu" 
                                    :key="index"
                                    class="gnb__item"
                                >
                                    <RouterLink
                                        v-if="!isMobile"
                                        :to="{ name: item.route }"
                                        class="gnb__link d-flex align-center"
                                        @click.native="removeGnbActiveClass"
                                    >
                                        {{ item.title }}
                                    </RouterLink>

                                    <span
                                        v-else
                                        class="gnb__link d-flex align-center"
                                    >
                                        {{ item.title }}
                                    </span>

                                    <transition name="fade">
                                        <ul
                                            v-if="isGnbActive"
                                            class="smenu d-flex flex-column gap-5"
                                        >
                                            <li
                                                v-for="(sub, subIndex) in item.gnbSubMenu"
                                                :key="subIndex"
                                                class="smenu__item"
                                            >
                                                <template v-if="sub.isExternal">
                                                    <a
                                                        :href="sub.externalLink"
                                                        class="smenu__link d-inline-flex align-center"
                                                        target="_blank"
                                                        @click="removeGnbActiveClass"
                                                    >
                                                        {{ sub.title }}
                                                    </a>
                                                </template>

                                                <template v-else>
                                                    <RouterLink
                                                        :to="{ name: sub.route, query: sub.query }"
                                                        class="smenu__link d-inline-flex align-center"
                                                        @click.native="removeGnbActiveClass"
                                                    >
                                                        {{ sub.title }}
                                                    </RouterLink>
                                                </template>
                                            </li>
                                        </ul>
                                    </transition>
                                </li>
                            </ul>

                            <transition name="fade">
                                <div
                                    v-if="isGnbActive"
                                    class="gnb-wrap__background"
                                ></div>
                            </transition>
                        </div>
                    </div>

                    <!-- ANCHOR: HButtons(PC) -->
                    <div
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="h-btns d-flex gap-4 align-center"
                    >
                        <div>
                            <!-- 언어 선택 버튼 -->
                            <LanguageMenu />
                            <!-- // 언어 선택 버튼 -->

                            <v-menu
                                v-if="false"
                                offset-y
                                class="custom-menu"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        v-bind="attrs"
                                        text
                                        small
                                        :ripple="false"
                                        v-on="on"
                                    >
                                        <v-icon size="32">
                                            fontagon-icons ft-icon ft-ic_globe_outline
                                        </v-icon>

                                        KR

                                        <v-icon size="32">
                                            fontagon-icons ft-icon ft-ic_arrow_down_fill
                                        </v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense>
                                    <v-list-item-group
                                        v-model="selectedItem"
                                        color="primary"
                                    >
                                        <v-list-item>
                                            <v-list-item>KOR</v-list-item>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </div>
                        
                        <v-btn
                            v-ripple="{ class: 'text-blue3' }"
                            color="blue1"
                            :small="$vuetify.breakpoint.mdAndUp"
                            depressed
                            class="text-white1"
                            outlined
                            @click="navigateToUserForOpenbadge"
                        >
                            로그인
                        </v-btn>
                        
                        <v-btn
                            color="blue1"
                            :small="$vuetify.breakpoint.mdAndUp"
                            depressed
                            class="text-white1"
                            @click="navigateToContactPage"
                        >
                            도입 문의
                        </v-btn>
                    </div>

                    <!-- ANCHOR: GNB(Mobile) -->
                    <transition name="fade">
                        <v-overlay
                            v-if="$vuetify.breakpoint.smAndDown && isDrawerActive"
                            @click="toggleDrawerMenu"
                        ></v-overlay>
                    </transition>

                    <transition name="slide">
                        <div
                            v-if="$vuetify.breakpoint.smAndDown && isDrawerActive"
                            class="gnb-mobile-wrap"
                        >
                            <v-btn
                                icon
                                color="gray3"
                                width="32"
                                height="32"
                                :ripple="false"
                                plain
                                class="gnb-mobile-wrap__close"
                                @click="toggleDrawerMenu"
                            >
                                <v-icon size="32">
                                    fontagon-icons ft-icon ft-ic_xMark_outline
                                </v-icon>
                            </v-btn>

                            <!-- ANCHOR: HButtons(Mobile) -->
                            <div
                                v-if="$vuetify.breakpoint.smAndDown"
                                class="h-btns d-flex gap-4"
                                :class="{'mb-5': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly}"
                            >
                                <v-btn
                                    v-ripple="{ class: 'text-blue3' }"
                                    color="blue1"
                                    :small="$vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.xsOnly"
                                    depressed
                                    class="text-white1"
                                    outlined
                                    @click="navigateToUserForOpenbadge"
                                >
                                    로그인
                                </v-btn>
                        
                                <v-btn
                                    color="blue1"
                                    :small="$vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.xsOnly"
                                    depressed
                                    class="text-white1"
                                    @click="navigateToContactPage"
                                >
                                    도입 문의
                                </v-btn>
                            </div>

                            <ul class="gnb-mobile d-flex flex-column justify-center align-center">
                                <li
                                    v-for="(item, index) in gnbMainMenu" 
                                    :key="index"
                                    class="gnb-mobile__item"
                                >
                                    <button
                                        class="gnb-mobile__link d-flex align-center justify-space-between"
                                        @click="item.gnbSubMenu ? toggleDrawerSubMenu(item.route) : goToRouteAndCloseDrawer(item.route)"
                                    >
                                        <span class="gnb-mobile__item-text">{{ item.title }}</span>

                                        <v-btn
                                            v-if="item.gnbSubMenu"
                                            icon
                                            color="gray3"
                                            :ripple="false"
                                            plain
                                            width="24"
                                            height="24"
                                            class="mr-2"
                                        >
                                            <v-icon size="24">
                                                fontagon-icons ft-icon
                                                {{ openCurrentDrawerSubMenu(item.route) ? 'ft-ic_chevron_up_outline' : 'ft-ic_chevron_down_outline' }}
                                            </v-icon>
                                        </v-btn>
                                    </button>

                                    <transition name="slide-expand">
                                        <ul
                                            v-if="openCurrentDrawerSubMenu(item.route)"
                                            class="smenu-mobile d-flex flex-column"
                                        >
                                            <li
                                                v-for="(sub, subIndex) in item.gnbSubMenu"
                                                :key="subIndex" 
                                                class="smenu-mobile__item"
                                            >
                                                <template v-if="sub.isExternal">
                                                    <a
                                                        :href="sub.externalLink"
                                                        class="smenu-mobile__link d-flex align-center"
                                                        target="_blank"
                                                        @click="closeDrawer"
                                                    >
                                                        {{ sub.title }}
                                                    </a>
                                                </template>

                                                <template v-else>
                                                    <RouterLink
                                                        :to="{ name: sub.route }"
                                                        class="smenu-mobile__link d-flex align-center"
                                                        @click.native="closeDrawer"
                                                    >
                                                        {{ sub.title }}
                                                    </RouterLink>
                                                </template>
                                            </li>
                                        </ul>
                                    </transition>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
            </div>
        </header>

        <!-- ANCHOR: HeaderSpacer -->
        <div class="header-spacer"></div>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import LanguageMenu from '@/components/LanguageMenu.vue';
// import { isMobile } from 'mobile-device-detect';

// import { isMobile } from 'mobile-device-detect';

export default {
    name: 'Header',

    components: {
        LanguageMenu, // 컴포넌트를 등록
    },

    // SECTION: Data Properties
    data: function () {
        return {
            isMobile: false,
            isGnbActive: false, // PC 네비 활성화
            isDrawerActive: false, // 서랍형 네비 활성화
            isDrawerSubMenuActive: null, // 서랍형 네비 서브 메뉴 활성화
            languageMenu: false,

            // isLangMenuOpen: false, // 언어 설정 메뉴 열림/닫힘 상태
            // currentLang: 'KOR', // 현재 언어 표시
            // availableLanguages: [
            //     { label: 'KOR', value: 'kor' },
            //     { label: 'ENG', value: 'eng' },
            // ],
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    computed: {
        gnbMainMenu() {
            return [
                {
                    title: '오픈배지', // this.$t('siteMenu.openbadge')
                    route: 'OpenbadgesPage',
                    // gnbSubMenu: false,
                    gnbSubMenu: [
                        { title: '오픈배지​', route: 'OpenbadgesPage' },
                        { title: '배지 갤러리​', route: 'BadgesGalleryPage', query: { category: 'all', search: '' } },
                    ],
                },
                {
                    title: '서비스',
                    route: 'ServicePage',
                    gnbSubMenu: [
                        { title: '도입-발행​', route: 'ServicePage' },
                        { title: '수령-보관​', route: 'ManagementPage' },
                        { title: '샘플 발행​', route: 'SampleBadgesPage' },
                        { title: '배지 검증​', isExternal: true, externalLink: 'https://www.lecos.co.kr/openbadge/verify.html' },
                    ],
                },
                {
                    title: '솔루션​',
                    route: 'SolutionPage',
                    gnbSubMenu: [
                        { title: '교육​', route: 'SolutionPage' },
                        { title: '기업', route: 'EnterprisePage' },
                        { title: '기관​', route: 'AssociationPage' },
                    ],
                },
                {
                    title: '레코스​',
                    route: 'CompanyPage',
                    gnbSubMenu: [
                        { title: '회사소개​', route: 'CompanyPage' },
                        { title: '언론보도​', route: 'PressPage' },
                        { title: '공지사항​', route: 'NoticePage' },
                    ],
                },
            ];
        }
    },

    mounted() {
        this.initLang();
        this.isMobileOrTablet();
        window.addEventListener('resize', this.handleResize);
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 언어설정
         * ============================================================ */
        initLang() {
            const urlLang = this.$route.params.lang;
            let i18nLang = '';
            let docLang = '';

            if (urlLang == 'kor') {
                i18nLang = 'ko';
                docLang = 'ko';
            } else if (urlLang == 'eng') {
                i18nLang = 'en';
                docLang = 'en';
            } else {
                i18nLang = 'ko';
                docLang = 'ko';
            }

            // i18n lang
            this.$i18n.locale = i18nLang;

            // doucment lang
            const html = document.documentElement;
            html.setAttribute('lang', docLang);
        },
        // changeLang(value) {
        //     // this.$router.push({
        //     //     name: this.$route.name,
        //     //     params: { lang: value },
        //     // });
        //     // this.currentLang = value === 'kor' ? 'KOR' : 'ENG';
        //     // this.isLangMenuOpen = false;

        //     // this.$router.go();

        //     if (value === 'eng') {
        //         // 영어 선택 시 특정 URL로 이동
        //         window.location.href = 'https://www.lecos.co.kr/eng';
        //     } else {
        //         // 기본 언어 처리
        //         window.location.href = 'https://www.lecos.co.kr/kor';
        //     }
        // },
        // toggleLangMenu() {
        //     this.isLangMenuOpen = !this.isLangMenuOpen;
        // },

        /* ============================================================
         * ANCHOR: window 이벤트
         * ============================================================ */
        handleResize() {
            this.isMobileOrTablet();
        },

        /* ============================================================
         * ANCHOR: 상단 링크
         * ============================================================ */

        /* ============================================================
         * ANCHOR: 해상도 체크
         * ============================================================ */

        /* ============================================================
         * ANCHOR: 네비게이션 제어
         * ============================================================ */
        isMobileOrTablet() {
            const info = navigator.userAgent;
            var flag = false;

            if( info.indexOf('iPhone') > -1
                    || info.indexOf('Android') > -1
                    || info.indexOf('iPad') > -1
                    || info.indexOf('iPod') > -1
            ) {
                flag = true;
            }
            this.isMobile = flag;
        },
        addGnbActiveClass() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.$refs.gnbWrap.classList.add('is-active');
                this.isGnbActive = true;
            }
        },
        removeGnbActiveClass() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.$refs.gnbWrap.classList.remove('is-active');
                this.isGnbActive = false;
            }
        },
        toggleDrawerMenu() {
            this.isDrawerActive = !this.isDrawerActive;
            if (this.isDrawerActive) {
                this.isDrawerSubMenuActive = null;
            }
        },
        toggleDrawerSubMenu(menu) {
            if (this.isDrawerSubMenuActive === menu) {
                this.isDrawerSubMenuActive = null;
            } else {
                this.isDrawerSubMenuActive = menu;
            }
        },
        goToRouteAndCloseDrawer(route) {
            if (this.$route.name !== route) {
                this.$router.push({ name: route });
            }
            this.closeDrawer();
        },
        openCurrentDrawerSubMenu(menu) {
            return this.isDrawerSubMenuActive === menu;
        },
        closeDrawer() {
            this.isDrawerActive = false;
        },
        navigateToContactPage() {
            this.$router.push({ name: 'ContactPage' });
            this.closeDrawer();
        },
        navigateToUserForOpenbadge() {
            this.$router.push({ name: 'UserForOpenbadgePage' });
            this.closeDrawer();
        },

        /* ============================================================
         * ANCHOR: Animation
         * ============================================================ */
    },
    // !SECTION: Methods
};
</script>
