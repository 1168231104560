<template>
    <div justify="center">  
        <v-dialog
            v-model="isDialog"
            max-width="600"
            content-class="elevation-0 rounded-0"
            transition="hide-on-leave"
        >
            <v-card
                class="rounded-0 pa-0"
                outlined
                color="#234663"
            >
                <div>
                    <v-img
                        :src="require('@/assets/img/main-modal/new-year-2025.png')"
                        alt=""
                    />
                </div>

                <v-card
                    class="rounded-0 pa-0"
                    color="#efefef"
                >
                    <v-card-actions class="pa-0">
                        <button
                            block
                            tile
                            text
                            style="font-weight: normal; flex: 1;"
                            :style="{
                                'font-size' : $vuetify.breakpoint.xsOnly ? '12px' : '14px',
                                'min-height': $vuetify.breakpoint.xsOnly ? '30px' : '38px'
                            }"
                            :ripple="false"
                            @click="hideForOneDay"
                        >
                            오늘 하루 보지 않기
                        </button>

                        <button
                            block
                            tile
                            text
                            style="font-weight: normal; flex: 1; border-left: 1px solid #dcdcdc;"
                            :style="{
                                'font-size' : $vuetify.breakpoint.xsOnly ? '12px' : '14px',
                                'min-height': $vuetify.breakpoint.xsOnly ? '30px' : '38px'
                            }"
                            @click="closeDialog"
                        >
                            닫기
                        </button>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
    name: 'NewYearModal2025',
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
    },
    data () {
        return {
            isDialog: this.dialog,
            // dialog: true,
        };
    },
    mounted() {
        // 페이지 로드 시 쿠키 확인
        const hideModal = Cookies.get('hideNewYearModal2025');
        if (hideModal) {
            this.isDialog = false;
        }
    },
    methods: {
        hideForOneDay() {
            // 쿠키에 1일 동안 유지되도록 값 저장
            Cookies.set('hideNewYearModal2025', 'true', { expires: 1 });
            this.isDialog = false;
            // this.$emit('update:dialog', false);
        },
        closeDialog() {
            this.isDialog = false;
            // this.$emit('update:dialog', false);
        },
    },
};
</script>
