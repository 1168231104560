var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600","content-class":"elevation-0 rounded-0","transition":"hide-on-leave"},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('v-card',{staticClass:"rounded-0 pa-0",attrs:{"outlined":"","color":"#234663"}},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/img/main-modal/new-year-2025.png'),"alt":""}})],1),_c('v-card',{staticClass:"rounded-0 pa-0",attrs:{"color":"#efefef"}},[_c('v-card-actions',{staticClass:"pa-0"},[_c('button',{staticStyle:{"font-weight":"normal","flex":"1"},style:({
                            'font-size' : _vm.$vuetify.breakpoint.xsOnly ? '12px' : '14px',
                            'min-height': _vm.$vuetify.breakpoint.xsOnly ? '30px' : '38px'
                        }),attrs:{"block":"","tile":"","text":"","ripple":false},on:{"click":_vm.hideForOneDay}},[_vm._v(" 오늘 하루 보지 않기 ")]),_c('button',{staticStyle:{"font-weight":"normal","flex":"1","border-left":"1px solid #dcdcdc"},style:({
                            'font-size' : _vm.$vuetify.breakpoint.xsOnly ? '12px' : '14px',
                            'min-height': _vm.$vuetify.breakpoint.xsOnly ? '30px' : '38px'
                        }),attrs:{"block":"","tile":"","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" 닫기 ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }